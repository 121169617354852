import React from 'react';
import { useDrag } from 'react-dnd';
import { Paper, Box, Typography } from '@mui/material';

const DraggableItem = ({ name, icon, color, type, item, description }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { ...item, type }, // Keeps existing item properties intact
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  // Check if the type is either 'SCENARIO' or 'USER_INPUT' to apply the same design
  const isScenarioOrUserInput = type === 'SCENARIO' || type === 'USER_INPUT';

  return (
    <Paper
      ref={drag}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        width: isScenarioOrUserInput ? '100%' : '220px', // Full width for scenarios and user-input, fixed width for others
        height: isScenarioOrUserInput ? '60px' : '160px', // Adjust height based on type
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        overflow: 'hidden',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
        },
        backgroundColor: isScenarioOrUserInput ? color || '#fffae6' : undefined, // Custom background only for scenarios and user-input
      }}
    >
      {/* Top Section with Icon on Left and Title Centered */}
      <Box
        sx={{
          backgroundColor: color || 'var(--card-background)',
          padding: isScenarioOrUserInput ? '8px 16px' : '16px 8px', // Custom padding only for scenarios and user-input
          display: 'flex',
          alignItems: 'center',
          justifyContent: isScenarioOrUserInput ? 'space-between' : 'center',
          height: isScenarioOrUserInput ? '100%' : '20px', // Adjust height only for scenarios and user-input
          color: 'var(--text-color)',
        }}
      >
        {icon && (
          <Box
            component="span"
            className={icon}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
              marginRight: isScenarioOrUserInput ? '8px' : '0px', // Space between icon and title only for scenarios and user-input
            }}
          />
        )}
        <Typography
          className="module-label"
          sx={{
            fontWeight: '600',
            fontSize: '0.8rem',
            textAlign: isScenarioOrUserInput ? 'left' : 'center',
            flexGrow: 1, // Make the text take the remaining space
            whiteSpace: 'normal', // Allow text to wrap
            overflowWrap: 'break-word', // Ensure words break to the next line
          }}
        >
          {name}
        </Typography>
      </Box>

      {/* Conditionally Render Bottom Section with Description */}
      {!isScenarioOrUserInput && (
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '8px',
            textAlign: 'center',
            color: '#6b7280',
            flexGrow: 1, // Allow the bottom section to take the remaining space
          }}
        >
          {description && (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '0.85rem',
                lineHeight: '1.2',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default DraggableItem;
