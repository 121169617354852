import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button, Box, Tooltip, Typography, Divider, IconButton, Dialog, DialogTitle,
  DialogContent, DialogActions, Tabs, Tab, TextField, InputAdornment, Alert, AlertTitle, CircularProgress
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SaveIcon from '@mui/icons-material/Save'; // Import Save icon
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DraggableItem from './DraggableItem';
import LoadProject from './LoadProject';
import SaveProject from './SaveProject';
import { modulesConfig } from '../config/modules';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import ProfileMenu from './ProfileMenu';
import useStore from '../zustandStore'; // Import useStore
import './Toolbar.css';

const Toolbar = ({ user, showDropdown, toggleDropdown, onNewProject, onProjectLoaded }) => {
  const [showYourWork, setShowYourWork] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState('Untitled');
  const [projects, setProjects] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openUsage, setOpenUsage] = useState(false);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const yourWorkRef = useRef(null);
  const nodes = useStore((state) => state.nodes);
  const edges = useStore((state) => state.edges);
  const results = useStore((state) => state.results);
  const userInput = useStore((state) => state.userInput);

  const toggleYourWork = () => setShowYourWork(!showYourWork);

  const handleToggleDropdown = (nodeId = null) => {
    if (showDropdown && nodeId === null) {
      toggleDropdown(null);
    } else {
      toggleDropdown(nodeId);
    }
    setShowYourWork(false);
  };

  const startNewProject = () => {
    setCurrentProjectId(null);
    setCurrentProjectName('Untitled');
    localStorage.setItem('currentProjectName', 'Untitled');
    onNewProject();
  };

  const handleProjectNameChange = (newProjectName) => {
    setCurrentProjectName(newProjectName);
    localStorage.setItem('currentProjectName', newProjectName);
  };

  const handleProjectSaved = (newProject) => {
    setProjects([...projects, newProject]);
    handleProjectNameChange(newProject.name);
  };

  const updateProject = async () => {
    if (!user) {
      toast.error('User not logged in');
      return;
    }

    if (!currentProjectId) {
      toast.error('No project selected for update');
      return;
    }

    const projectData = { nodes, edges, results, userInput };
    setLoading(true);

    try {
      const { error } = await supabase
        .from('projects')
        .update({ data: projectData, public: false })  // Assuming public flag is false here
        .eq('id', currentProjectId);

      if (error) {
        console.error("Update Error:", error);
        throw error;
      }

      toast.success('Project updated successfully!');
    } catch (error) {
      toast.error('Error updating project');
      console.error("Error updating project:", error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error('Error signing out');
    } else {
      window.location.reload();
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAccountOpen = () => {
    setOpenEditAccount(true);
    handleProfileMenuClose();
  };

  const handleUsageOpen = () => {
    setOpenUsage(true);
    handleProfileMenuClose();
  };

  const handleDialogClose = () => {
    setOpenEditAccount(false);
    setOpenUsage(false);
    setShowYourWork(false);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      (!yourWorkRef.current || !yourWorkRef.current.contains(event.target))
    ) {
      if (showDropdown) {
        toggleDropdown(null);
      }
      if (showYourWork) {
        setShowYourWork(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown, showYourWork]);

  useEffect(() => {
    if (user) {
      const fetchCredits = async () => {
        const { data, error } = await supabase
          .from('users')
          .select('credits')
          .eq('id', user.id)
          .single();

        if (error) {
          toast.error('Error fetching credits');
        } else {
          setCredits(data.credits);
        }
      };

      fetchCredits();
    }
  }, [user]);

  useEffect(() => {
    const savedProjectName = localStorage.getItem('currentProjectName');
    if (savedProjectName) {
      setCurrentProjectName(savedProjectName);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentProjectName', currentProjectName);
  }, [currentProjectName]);

  const filteredModulesConfig = {
    userInput: modulesConfig.userInput.filter(module =>
      module.data.label.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    scenarios: Object.keys(modulesConfig.scenarios).reduce((acc, key) => {
      if (modulesConfig.scenarios[key].label.toLowerCase().includes(searchQuery.toLowerCase())) {
        acc[key] = modulesConfig.scenarios[key];
      }
      return acc;
    }, {}),
    actions: modulesConfig.actions.filter(action =>
      action.data.label.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    standaloneModules: modulesConfig.standaloneModules.filter(module =>
      module.data.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
  };

  const renderStandaloneModules = () => {
    const groupedBySubcategory = filteredModulesConfig.standaloneModules.reduce((acc, module) => {
      const { subcategory } = module.data;
      if (!acc[subcategory]) {
        acc[subcategory] = [];
      }
      acc[subcategory].push(module);
      return acc;
    }, {});

    return Object.keys(groupedBySubcategory).map((subcategory) => (
      <Box key={subcategory}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333', margin: '10px 0' }}>
          {subcategory}
        </Typography>
        <Box className="dropdown-grid">
          {groupedBySubcategory[subcategory].map((module) => (
            <Tooltip title={module.data.description} key={module.id}>
              <Box className="dropdown-item" style={{ borderColor: module.data.color }}>
                <DraggableItem
                  name={module.data.label}
                  icon={module.data.icon}
                  type="MODULE"
                  item={module}
                  color={module.data.color}
                  description={module.data.description}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>
    ));
  };

  const renderScenarios = () => {
    return (
      <>
        <Typography variant="h6" className="menu-heading">Templates</Typography>
        <Box className="custom-scenarios-grid">
          {Object.keys(filteredModulesConfig.scenarios).map((scenarioKey) => (
            <Box 
              key={scenarioKey}
              sx={{
                borderColor: modulesConfig.scenarios[scenarioKey].color,
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
              }}
            >
              <DraggableItem
                name={modulesConfig.scenarios[scenarioKey].label}
                icon={modulesConfig.scenarios[scenarioKey].icon}
                type="SCENARIO"
                item={modulesConfig.scenarios[scenarioKey]}
                color={modulesConfig.scenarios[scenarioKey].color}
              />
            </Box>
          ))}
        </Box>
      </>
    );
  };

  const handleProjectLoaded = useCallback((projectId, projectName, nodes, edges, results, userInput) => {
    setCurrentProjectId(projectId);
    handleProjectNameChange(projectName);

    if (onProjectLoaded) {
      onProjectLoaded(nodes, edges, results, userInput);
    }
  }, [onProjectLoaded]);

  return (
    <Box className="toolbar">
      {credits === 0 && (
        <Alert severity="warning" style={{ position: 'fixed', top: 0, right: 0, zIndex: 9999 }}>
          <AlertTitle>Insufficient Credits</AlertTitle>
          You need to fund your account to continue working.
        </Alert>
      )}
      {user ? (
        <Box className="toolbar-buttons">
          <Tooltip title="Add a Node" placement="bottom">
            <IconButton onClick={() => handleToggleDropdown()}>
              <AddCircleOutlineIcon className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Your Work" placement="bottom">
            <IconButton onClick={toggleYourWork}>
              <FolderOpenIcon className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Start New Project" placement="bottom">
            <IconButton onClick={startNewProject}>
              <NoteAddIcon className="icon" />
            </IconButton>
          </Tooltip>
          {currentProjectId && (
  <Tooltip title="Update Project" placement="bottom">
    <IconButton
      onClick={updateProject}
      className="update-project-button"
      disabled={loading || !currentProjectId}
      sx={{
        backgroundColor: '#1976d2',
        color: '#fff',
        '&:hover': { backgroundColor: '#1565c0' },
      }}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : <SaveIcon className="icon" />}
    </IconButton>
  </Tooltip>
)}

        </Box>
      ) : (
        <Button variant="contained" color="primary" href="/signup">
          Sign Up
        </Button>
      )}
      <Box className="current-project" style={{ flexGrow: 1, textAlign: 'center' }}>
        <Typography
          variant="h6"
          className="current-project-name"
          onClick={toggleYourWork}
          style={{ cursor: 'pointer', display: 'inline-block', maxWidth: '300px' }}
        >
          {currentProjectName}
        </Typography>
      </Box>
      {user && (
        <Box className="profile-and-credits">
          <Box className="toolbar-buttons">
            <Tooltip title="Credits" placement="left">
              <IconButton onClick={handleUsageOpen}>
                <MonetizationOnIcon className="icon" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="profile-menu">
            <ProfileMenu
              user={user}
              anchorEl={anchorEl}
              openEditAccount={openEditAccount}
              openUsage={openUsage}
              handleProfileMenuOpen={handleProfileMenuOpen}
              handleProfileMenuClose={handleProfileMenuClose}
              handleEditAccountOpen={handleEditAccountOpen}
              handleUsageOpen={handleUsageOpen}
              handleDialogClose={handleDialogClose}
              signOut={signOut}
            />
          </Box>
        </Box>
      )}
      {showDropdown && user && (
        <Box className="dropdown-menu" ref={dropdownRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <TextField
              placeholder="Search nodes..."
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IconButton onClick={() => toggleDropdown(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {filteredModulesConfig.userInput.length > 0 && (
            <>
              <Box className="dropdown-grid">
                {filteredModulesConfig.userInput.map((inputNode) => (
                  <Tooltip title={inputNode.data.description} key={inputNode.id}>
                    <Box className="dropdown-item" style={{ borderColor: inputNode.data.color }}>
                      <DraggableItem
                        name={inputNode.data.label}
                        icon={inputNode.data.icon}
                        type="USER_INPUT"
                        item={inputNode}
                        color={inputNode.data.color}
                        description={inputNode.data.description}
                      />
                    </Box>
                  </Tooltip>
                ))}
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}
          
          {renderScenarios()}

          {filteredModulesConfig.standaloneModules.length > 0 && (
            <>
              <Typography variant="h6" className="menu-heading">Modules</Typography>
              {renderStandaloneModules()}
            </>
          )}
          {filteredModulesConfig.actions.length > 0 && (
            <>
              <Typography variant="h6" className="menu-heading">Actions</Typography>
              <Box className="dropdown-grid">
                {filteredModulesConfig.actions.map((action) => (
                  <Tooltip title={action.data.description} key={action.id}>
                    <Box className="dropdown-item" style={{ borderColor: action.data.color }}>
                      <DraggableItem
                        name={action.data.label}
                        icon={action.data.icon}
                        type="ACTION"
                        item={action}
                        color={action.data.color}
                        description={action.data.description}
                      />
                    </Box>
                  </Tooltip>
                ))}
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}
        </Box>
      )}
      {user && (
        <Dialog open={showYourWork} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>Your Work</DialogTitle>
          <DialogContent dividers className="your-work-modal" ref={yourWorkRef}>
            <Tabs
              value={tabIndex}
              onChange={(e, newValue) => setTabIndex(newValue)}
              aria-label="Project Tabs"
              TabIndicatorProps={{ style: { backgroundColor: '#4f46e5' } }}
            >
              <Tab label="Save Project" sx={{ color: tabIndex === 0 ? 'primary.main' : 'inherit' }} />
              <Tab label="Load Project" sx={{ color: tabIndex === 1 ? 'primary.main' : 'inherit' }} />
            </Tabs>
            {tabIndex === 0 && (
              <Box className="work-box">
                <SaveProject
                  user={user}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  onProjectSaved={handleProjectSaved}
                  handleProjectNameChange={handleProjectNameChange}
                />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box className="work-box">
                <LoadProject
                  user={user}
                  setCurrentProjectId={setCurrentProjectId}
                  setCurrentProjectName={handleProjectNameChange}
                  projects={projects}
                  setProjects={setProjects}
                  onProjectLoaded={handleProjectLoaded}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Toolbar;
