import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { Box, IconButton, Paper, Typography, LinearProgress } from '@mui/material';
import { Delete as DeleteIcon, PlayArrow as PlayArrowIcon, Visibility as VisibilityIcon, Add as AddIcon } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './CustomNode.css';

const CustomNode = ({ id, data }) => {
  const [loading, setLoading] = useState(data.loading);
  const [progress, setProgress] = useState(0);
  const userInput = useSelector((state) => state.userInput);

  useEffect(() => {
    if (data.loading !== undefined) {
      setLoading(data.loading);
    }

    // If the content is fully loaded, set progress to 100%
    if (!data.loading && data.result && data.label !== 'Come Up with New Ideas' && data.type !== 'userInputNode') {
      setProgress(100); // Set progress to 100% when content is available
      toast.success(
        <div style={{ color: '#fff', fontWeight: 'bold' }}>
          🎉 Content for <strong>{data.label}</strong> is ready!
        </div>, 
        { containerId: `toast-container-${id}` }
      );
      if (data.onViewResult) {
        data.onViewResult(id);
      }
    } else if (data.loading) {
      setProgress(0); // Set progress to 0% when loading starts
    }
  }, [data.loading, data.result, id, userInput, data.label, data.type]);

  useEffect(() => {
    if (loading && progress < 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            return 100;
          }
          return prevProgress + 0.3; // Adjust this to control the speed of progress
        });
      }, 100); // 100ms interval for updating progress
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [loading, progress]);
  
  const handleAddNodeClick = () => {
    data.toggleDropdown(id);
  };

  const handleViewResultClick = () => {
    if (data.onViewResult) {
      data.onViewResult(id);
    }
  };

  const handleDeleteClick = () => {
    data.onDelete(id);
  };

  const handleRunClick = async () => {
    if (loading) return; // Prevent execution while loading

    const connectedNodeResults = data.getConnectedNodeResults(id);
    const combinedInput = connectedNodeResults.join(' ');
    await data.onExecute(combinedInput);
    setLoading(true); // Set loading to true when execution starts
  };

  return (
    <Paper className="custom-node" elevation={3}>
      <Handle
        type="target"
        position="left"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      <Box className="custom-node-header" style={{ backgroundColor: data.color }}>
        <i className={`${data.icon} node-header-icon`} />
        {data.label}
      </Box>
      <Box className="custom-node-content">
        <Typography variant="body2" className="node-description">
          {data.description}
        </Typography>
        <Box className="node-loading">
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{
              width: '100%',
              height: '10px',
              borderRadius: '5px',
            }}
            classes={{
              barColorPrimary: 'linear-progress-bar', // Apply the custom color to the filled part of the progress bar
            }}
          />
        </Box>
      </Box>
      <Box className="button-container">
        <Box className="left-buttons">
          <IconButton onClick={handleViewResultClick} className="icon-button gradient-icon" disabled={loading}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={handleRunClick} className="icon-button gradient-icon" disabled={loading}>
            <PlayArrowIcon />
          </IconButton>
          <IconButton onClick={handleDeleteClick} className="icon-button gradient-icon" disabled={loading}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Handle
        type="source"
        position="right"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      {!loading && (
        <IconButton
          className="node-add-button"
          onClick={handleAddNodeClick}
          color="primary"
          style={{
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            background: '#6366f1',
            color: 'white',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AddIcon />
        </IconButton>
      )}
      <ToastContainer
        enableMultiContainer
        containerId={`toast-container-${id}`}
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          marginTop: '-30%',
          maxWidth: '300px',
          backgroundColor: 'transparent',
        }}
        toastStyle={{
          backgroundColor: '#4f46e5',
          color: '#fff',
          fontWeight: '500',
        }}
      />
    </Paper>
  );
};

export default CustomNode;
